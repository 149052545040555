<template>
        <span>
        <div class="tutorial-selection-menu" style="bottom: unset; top: 38%;
        left: 1%;
        max-width: fit-content;">
            <template
                    v-for="({id, obj, tooltip}) in formattedSequencesList"
            >
<!--                <v-tooltip-->
<!--                        :key="id"-->
<!--                        content-class="education-mode-menu-tooltip"-->
<!--                        :attach="'#' + 'tutorial-selection-menu__tab' + id"-->
<!--                        top-->
<!--                >-->
<!--                    <template #activator="{on}">-->
                         <div
                                 class="tutorial-selection-menu__tab"
                                 :id="'tutorial-selection-menu__tab' + id"
                                 v-tooltip="{content: `<div class='education-mode-menu-tooltip'><span>${tooltip}</span></div>`}"
                                 :class="getActiveSequenceId === id && 'tutorial-selection-menu__tab--active'"
                                 @click="startSequence(id)"
                         >
                {{ obj.prettyName }}
            </div>
<!--                    </template>-->
<!--                    <template #default>{{tooltip}}-->
<!--                    </template>-->
<!--                </v-tooltip>-->
            </template>

        </div>
            <!--        <span v-if="getCurrentSequenceId === 'workstation_general_info'"-->
            <!--              style="position: absolute; width: 100vw; height: 100vh; z-index: 5; cursor: default"></span>-->
    </span>
</template>


<script>
    import { sequencesObj } from '@/store/modules/sequences'
    import {mapGetters} from 'vuex'

    export default {
        name: "InjectorTutorialSelectionMenu",
        data: () => ({
            modalModel: false,
            sequencesList: [
                {
                    id: 'injector_general_info',
                    tooltip: 'Назначение расходных материалов и частей прибора.'
                },
                {
                    id: 'injector_head_setup',
                    tooltip: 'Установка расходных материалов и забор жидкостей на примере одноразового комплекта SDS-CTP-SPK и многоразового комплекта SDS MP1'

                },
            ]
        }),
        computed: {
            ...mapGetters('sequences_store', ['getSequences', 'getActiveSequenceId']),
            formattedSequencesList() {
                return this.sequencesList.map(item => ({...item, obj: sequencesObj[item.id]}))
            }
        },
        methods: {
            startSequence(id) {
                // if (id === 'injector_general_info' && this.getActiveSequenceId === 'injector_general_info') return
                this.$store.dispatch('sequences_store/startSequence', {sequenceId: id})
            }
        }
    }
</script>

<style lang="scss">
     @import "@/scss/components/tutorial-selection-menu";

</style>